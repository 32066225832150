export const environment = {
    production: false,
    auth: {
        clientID: 'UnLC1FVvnkpOd6rG5eHdlXvGwjDdKHAV',
        audience: 'https://media.ra.dev/rate-optics',
        domain: 'media-dev.auth0.com',
        algorithm: 'RSA256',
        responseType: 'token id_token',
        scope: 'openid profile email',
    },
    api: 'https://api.dev.rateoptics.io',
    api2: 'https://api2.dev.rateoptics.io',
};
