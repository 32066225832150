import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CellKeyDownEvent, ICellRendererParams } from 'ag-grid-community';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckBoxCellParams } from '@models/ag-grid';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-checkbox-cell',
    templateUrl: './checkbox-cell.component.html',
    styleUrls: ['./checkbox-cell.component.scss'],
    standalone: false,
})
export class CheckboxCellComponent implements ICellRendererAngularComp {
    @ViewChild('matCheckbox', { static: true }) matCheckboxRef!: MatCheckbox;
    public params: CheckBoxCellParams;
    checked: boolean;
    disabled = false;
    documentKeydownListener: () => void;

    constructor() {
    }

    agInit(params: CheckBoxCellParams): void {
        this.params = params;
        // excess listeners can cause performance problems in grids with lots of checkbox cells
        // if this is an issue follow the example on the customer-rate-card-metric-config.component.ts
        // to attach the listener at the grid level
        if(this.params.api && !this.params.disableEnterSelect){
            this.params.api.addEventListener('cellKeyDown', this.onCellKeyDown.bind(this));
        }
        this.checked = params.value;
        this.disabled = params.updateDisabled ? params.updateDisabled(params) : false;
    }

    change(event: MatCheckboxChange) {
        this.params.node.data[this.params.colDef.field] = event.checked;
        this.params?.callBack(this.params);
    }

    onCellKeyDown(event: CellKeyDownEvent){
        const keyboardEvent = event.event as KeyboardEvent;
        const focusedCell = this.params.api.getFocusedCell();
        if(focusedCell && focusedCell.column === this.params.column && focusedCell.rowIndex === this.params.node.rowIndex){
            if(keyboardEvent.key === ' ' || keyboardEvent.key === 'Enter'){
                this.matCheckboxRef.toggle();
                this.change({ checked: this.matCheckboxRef.checked } as MatCheckboxChange);
                keyboardEvent.preventDefault();
            }
        }
    }

    refresh(params: ICellRendererParams) {
        this.checked = params.value;
        return true;
    }
}
