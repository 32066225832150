/*eslint-disable @typescript-eslint/member-ordering */

import {
    DeferrableFilter,
    FilterTemplate,
    GetTemplateValueForSaveReport,
    MultiSelectFilter,
} from '@models/filter-types';
import {
    ChannelItem,
    LookupItem,
    MarketItem,
    SegmentItem,
    StationItem,
    ProductTypeItem,
} from '../lookup';
import moment from 'moment';
import { Moment } from 'moment';
import { cloneDeep } from 'lodash';
import {
    dateFormatter,
} from '@shared/helpers/functions/helpers';
import { Segmentation, UiConfig } from '@models/config';
import {
    cascadeToFilter,
    generateFilters,
    updatePeriod,
} from '@shared/helpers/functions/filter-helpers';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@services/notification/notification.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import {
    channelGroupFilter,
    channelsFilter,
    endDateFilter,
    loadReport,
    marketsFilter,
    periodFilter,
    saveReport,
    startDateFilter,
    stationsFilter,
    productsFilter,
    productTypesFilter,
} from '@models/base_filters';
import { FilterSetComponent } from '@shared/components/filters/filter-set/filter-set.component';
import { FilterSettings } from '@models/filter';

const DEFAULT_WEEKS_TO_FILTER = 12;
const SUNDAY = 0;
const MONDAY = 1;

type DateView = 'Day' | 'Week' | 'Month' | 'Year';

export class DigitalRateCardFilters implements FilterTemplate {
    public marketItems: MarketItem[] = [];
    public stationItems: StationItem[] = [];
    private channelItems: ChannelItem[] = [];
    public loading: boolean;
    public primaryRateValue?: string;
    public alertFilter?: string[] = [];
    public tierValue = [];
    public savedReportLoaded = false;
    public rateCardTabsData?: number = 1;
    public showMetrics: boolean;

    public dynamicFilters: { [segmentType: string]: MultiSelectFilter<SegmentItem> } = {};

    private defaultStartDate = moment().startOf('day');
    private defaultEndDate = this.defaultStartDate.clone().startOf('day').add(DEFAULT_WEEKS_TO_FILTER, 'weeks');

    constructor(
        private lookupSvc: LookupV2Service,
        public config: UiConfig,
        private matDialog: MatDialog,
        private snackBar: NotificationService,
        private filterSet: FilterSetComponent,
        public tabId?: string,
        private afterLoad = () => {
        },
    ) {
    }

    settings: FilterSettings = {
        config: this.config,
        lookupSvc: this.lookupSvc,
        hasDigitalRates: true,
        screenName: UrlStore.screenNames.digitalRateCard,
    };

    channelGroup = channelGroupFilter(this.settings)
        .OnLoad((items: LookupItem[]) => {
            if (this.channelGroup.Value === null && items.length === 1) {
                this.channelGroup.Default(items[0]);
                this.channelGroup.Toggle(true);
            }
        })
        .OnChange((item: LookupItem) => {
            if (item) {
                if (this.markets) {
                    this.markets.Value = [];
                    const newOptions = this.marketItems.filter(i => i.channelGroups.includes(item.id));
                    this.markets.Options(newOptions);
                    if (this.markets.Value.length === 0 && newOptions.length === 1) {
                        this.markets.Value = newOptions;
                    }
                }
                this.getDynamicFilters(item);
                updatePeriod(item?.id, this.period, this.settings.screenName, this.lookupSvc);
            } else {
                this.dynamicFilters = {};
            }
        });

    markets = marketsFilter(this.settings)
        .OnLoad((items: MarketItem[]) => {
            if (this.marketItems.length === 0) {
                this.marketItems = cloneDeep(items);
            }
            if (this.channelGroup.Value) {
                if (this.markets.Value.length === 0 && items.length === 1) {
                    this.markets.Default(items);
                }
            }
            this.markets.SetHighlightOptions(items);
        })
        .OnChange((items: MarketItem[]) => {
            // when the market changes we filter the list of stations to match
            if (this.stations && this.channelGroup.Value && !this.anyFilterOpen()) {
                const newOptions = this.stationItems.filter(i =>
                    items.map(item => item.id).includes(i.marketId)
                    && i.channelGroups.includes(this.channelGroup.Value.id));
                cascadeToFilter(this.stations, newOptions);
            }
        });


    stations = stationsFilter(this.settings)
        // load the list of stations and filter by selected markets
        .OnLoad((items: StationItem[]) => {
            // store the full list of stations as the base for filters
            if (this.stationItems.length === 0) {
                this.stationItems = cloneDeep(items);
            }
            const newOptions = this.stationItems.filter(i =>
                this.markets.Value.map(market => market.id).includes(i.marketId)
                && i.channelGroups.includes(this.channelGroup.Value.id));
            cascadeToFilter(this.stations, newOptions);
            this.stations.SetHighlightOptions(newOptions);
        })
        .OnChange((items: StationItem[]) => {
            this.stations.SetHighlightOptions(items);
            if (this.channels && this.channelGroup.Value && !this.anyFilterOpen()) {
                const newOptions = this.channelItems.filter(i =>
                    items.map(item => item.id).includes(i.stationId)
                    && i.channelGroup === this.channelGroup.Value.id);
                cascadeToFilter(this.channels, newOptions);
            }
        });

    channels = channelsFilter(this.settings)
        .OnLoad((items: ChannelItem[]) => {
            if (this.channelItems.length === 0) {
                this.channelItems = cloneDeep(items);
            }
            const newOptions = this.channelItems.filter(i =>
                this.stations.Value.map(station => station.id).includes(i.stationId)
                && i.channelGroup === this.channelGroup.Value.id);
            cascadeToFilter(this.channels, newOptions);
        })
        .OnChange((items: ChannelItem[]) => {
            if (this.anyFilterOpen()) {
                return;
            } else if (this.channels.Value.length === 0) {
                cascadeToFilter(this.productTypes, []);
                return;
            }

            this.productTypes.isLoading = true;
            this.lookupSvc.getProductTypes(this.channels.Value.map(item => item.id))
                .subscribe((productTypes) => {
                    cascadeToFilter(this.productTypes, productTypes);
                }, err => {
                    console.error(err);
                    this.productTypes.loadError = 'Failed to Load';
                });
            this.productTypes.isLoading = false;

            const segmentation = this.config
                .groupSegmentation[this.channelGroup.Value?.id]?.digital_rate_card;

            if (segmentation?.length) {
                const firstSegmentation = segmentation.sort((first, second) => first.segment_order - second.segment_order)[0];

                const firstFilter = this.dynamicFilters[firstSegmentation.segment_type];
                firstFilter.isLoading = true;
                this.lookupSvc.getDigitalSegments(this.channels.Value.map(item => item.id), [], firstSegmentation)
                    .subscribe(result => {
                        cascadeToFilter(firstFilter, result);

                    }, err => {
                        console.error(err);
                        firstFilter.isLoading = false;
                        firstFilter.loadError = 'Failed to Load';
                    });
            }
        });

    productTypes = productTypesFilter()
        .OnChange((items: ProductTypeItem[]) => {
            if (this.productTypes && !this.anyFilterOpen()) {
                if (this.productTypes.Value.length > 0) {
                    this.products.isLoading = true;
                    this.lookupSvc.getProducts(this.channels.Value.map(item => item.id), items.map(item => item.id))
                        .subscribe(products => {
                            cascadeToFilter(this.products, products);
                        }, err => {
                            console.error(err);
                            this.products.loadError = 'Failed to Load';
                        });
                    this.products.isLoading = false;
                } else {
                    if (!this.savedReportLoaded) {
                        cascadeToFilter(this.products, []);
                    }
                }
            }
        });

    products = productsFilter()
        .IsDynamicFilterParent(true);

    period = periodFilter(this.settings)
        .OnLoad(items => {
            if (this.period.Value) {
                this.period.Value = items.find(item => item.id === this.period.Value.id);
            }
        })
        .OnChange(v => {
            // update the views for the date selectors to match the period
            this.startDate.View(v.name as DateView);
            this.endDate.View(v.name as DateView);
            this.resetDates();
        });

    startDate = startDateFilter()
        .Default(this.defaultStartDate.day(MONDAY))
        .OnChange((date: Moment) => {
            this.resetDates();
            this.endDate.Validate();
        })
        .Clear(() => this.startDate.Value = this.defaultStartDate.day(MONDAY))
        .Filter((date: Moment) => {
            const filterDate = date.clone().subtract(7, 'days');
            return !!this.period.Value?.startDateMap?.[filterDate.year()]?.[filterDate.month() + 1]?.[filterDate.date()];
        })
        .ResetDate((date: Moment) => {
            this.startDate.Value = date;
            this.resetDates();
            return this.startDate.Value;
        })
        .Validator(_ => !this.isTooManyPeriods(), () => `Please limit your search to ${this.period.Value.maxQueryCount} periods or fewer`);

    endDate = endDateFilter()
        .Default(this.defaultEndDate.day(SUNDAY))
        .OnChange(_ => {
            this.resetDates();
            this.startDate.Validate();
        })
        .Clear(() => this.endDate.Value = this.defaultEndDate.day(SUNDAY))
        .Filter((date: Moment) => {
            const filterDate = date.clone().add(7, 'days');
            return date.isAfter(this.startDate.Value)
                && !!this.period.Value?.endDateMap?.[filterDate.year()]?.[filterDate.month() + 1]?.[filterDate.date()];
        })
        .ResetDate((date: Moment) => {
            this.endDate.Value = date;
            this.resetDates();
            return this.endDate.Value;
        })
        .Validator(_ => !this.isTooManyPeriods(), () => `Please limit your search to ${this.period.Value.maxQueryCount} periods or fewer`);


    dayparts: null; // This is only here because political and dynamic have dayparts and it's needed for getRateCardSegments

    getRateCardSegments() {
        console.log('Digital does not have any dayparts');
    }

    resetDates(): void {
        /* `Finds the start date (or end date) that is immediately on or before the current date,
        so when you change periods up we find the period start date that preceeds the date you have selected.
        i.e. if you moved from week to month today we’d bounce from 10/19/2020 → 9/28/2020` */
        if (this.period.Value) {
            const startDateIndex = this.period.Value.startDates.findIndex(date =>
                date > dateFormatter(this.startDate.Value.toDate(), 'yyyy-MM-dd')) - 1;
            if ('Year' === this.period.Value.name) {
                this.startDate.setValueNoOnChange(moment(this.period.Value.startDates[startDateIndex], 'YYYY-MM-DD'));
                this.endDate.setValueNoOnChange(moment(this.period.Value?.endDates[this.period.Value.endDates.findIndex(endDate =>
                    endDate > dateFormatter(this.startDate.Value.toDate(), 'yyyy-MM-dd'))], 'YYYY-MM-DD'));
                return;
            }
            const possibility1 = moment(this.period.Value.startDates[startDateIndex]).diff(moment(this.startDate.Value));
            const possibility2 = moment(this.period.Value.startDates[startDateIndex + 1]).diff(moment(this.startDate.Value));
            const newStartDate = Math.abs(possibility1) > Math.abs(possibility2) ?
                this.period.Value.startDates[startDateIndex + 1] : this.period.Value.startDates[startDateIndex];
            const endDateIndex = this.period.Value.endDates.findIndex(endDate =>
                endDate > dateFormatter(this.endDate.Value.toDate(), 'yyyy-MM-dd')
                && endDate > newStartDate);
            const newEndDate =
                this.period.Value?.endDates[
                    endDateIndex - (moment(newStartDate).isAfter(this.endDate.Value) ? 0 : 1)
                ];
            if (newStartDate && !this.startDate.Value.isSame(newStartDate)) {
                this.startDate.Value = moment(newStartDate, 'YYYY-MM-DD');
            }
            if (newEndDate && !this.endDate.Value.isSame(newEndDate)) {
                this.endDate.Value = moment(newEndDate, 'YYYY-MM-DD');
            }
        }
    }

    getDynamicFilters(channelGroupItem) {
        const groupSegmentation = this.config.groupSegmentation[channelGroupItem.id]?.digital_rate_card || [];
        this.dynamicFilters = generateFilters(
            () => this.channelItems.filter(i => i.channelGroup === this.channelGroup.Value?.id).map(i => i.id),
            () => [],
            groupSegmentation,
            (
                channelIds: number[],
                daypartIds: number[],
                segmentation: Segmentation,
                filterValues: {
                    [segmentType: string]: number[];
                },
                clearCache?: boolean,
            ) => this.lookupSvc.getDigitalSegments(channelIds, daypartIds, segmentation, filterValues, clearCache),
        );
    }

    isTooManyPeriods(): boolean {
        if (this.period.Value?.maxQueryCount) {
            return this.endDate.Value.diff(this.startDate.Value, 'days')
                > this.period.Value.averageDaysPer * this.period.Value.maxQueryCount;
        }
        return false;
    }

    getMetricKey(): string {
        return UrlStore.localStorageKeys.digitalRateCardMetrics;
    }

    getMetricOrderKey(): string {
        return UrlStore.localStorageKeys.digitalRateCardMetricOrder;
    }

    getSegmentsKey(): string {
        return UrlStore.localStorageKeys.digitalRateCardSegments;
    }

    getSegmentsOrderKey(): string {
        return UrlStore.localStorageKeys.digitalRateCardSegmentsOrder;
    }

    metricsData = {
        metricsKey: this.getMetricKey(),
        metricsOrderKey: this.getMetricOrderKey(),
    };

    save = () => saveReport(
        this.matDialog,
        UrlStore.screenNames.digitalRateCard,
        this,
        this.lookupSvc,
        this.snackBar,
        this.metricsData,
        this.getSegmentsKey(),
        this.getSegmentsOrderKey(),
        false,
        true,
        GetTemplateValueForSaveReport(this),
        this.filterSet.allFiltersSelectedCheck(),
        this.rateCardTabsData,
    );

    load = () => loadReport(this.matDialog,
                            UrlStore.screenNames.digitalRateCard,
                            this,
                            this.lookupSvc,
                            this.getMetricKey(),
                            this.getMetricOrderKey(),
                            this.getSegmentsKey(),
                            this.getSegmentsOrderKey(),
                            this.afterLoad,
                            this.rateCardTabsData,
                            this.tabId);

    anyFilterOpen: () => boolean = () =>
        Object.keys(this)
            .filter(key => this[key] instanceof DeferrableFilter)
            .filter(key => (this[key] as DeferrableFilter<unknown, unknown>).isOpened)
            .length > 0;
}
