/*eslint-disable @typescript-eslint/member-ordering */

import {
    DeferrableFilter,
    FilterTemplate,
    GetTemplateValueForSaveReport,
    MultiSelectFilter, SelectFilter,
} from '@models/filter-types';
import { DynamicRateCardTabModuleConfig } from '@models/config';
import {
    BooleanLookupItem,
    ChannelItem, DaypartLookupItem,
    LookupItem,
    MarketItem, MetricsData, Quarter,
    SegmentItem,
    StationItem,
} from '../lookup';
import moment from 'moment';
import { Moment } from 'moment';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs/operators';
import {
    dateFormatter,
} from '@shared/helpers/functions/helpers';
import { Segmentation, UiConfig } from '@models/config';
import {
    cascadeToFilter,
    generateFilters,
    updatePeriod,
} from '@shared/helpers/functions/filter-helpers';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@services/notification/notification.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import {
    channelGroupFilter,
    channelsFilter,
    daypartsFilter,
    dowsFilter,
    endDateFilter,
    loadReport,
    marketsFilter,
    periodFilter,
    saveReport,
    spotTypesFilter,
    startDateFilter,
    stationsFilter,
} from '@models/base_filters';
import { FilterSetComponent } from '@shared/components/filters/filter-set/filter-set.component';
import { FilterSettings } from '@models/filter';

const DEFAULT_WEEKS_TO_FILTER = 12;
const SUNDAY = 0;
const MONDAY = 1;

type DateView = 'Day' | 'Week' | 'Month' | 'Year';

export class DynamicRateCardFiltersV2 implements FilterTemplate {
    public marketItems: MarketItem[] = [];
    public stationItems: StationItem[] = [];
    public channelItems: ChannelItem[] = [];
    public loading: boolean;

    // Saved Report Variables
    public primaryRateValue?: string;
    public alertFilter?: string[] = [];
    public tierValue?: number[] = [];
    public quarterlyPCode = false;
    public quarterlyPoliticalSummary = false;
    public totals = false;
    public showMetrics = false;

    public savedReportLoaded = false;
    public changeDates?: boolean = true;
    public rateCardTabsData?: number = 1;
    public dynamicFilters: { [segmentType: string]: MultiSelectFilter<SegmentItem> } = {};

    private defaultStartDate = moment().startOf('day');
    private defaultEndDate = this.defaultStartDate.clone().startOf('day').add(DEFAULT_WEEKS_TO_FILTER, 'weeks');

    constructor(
        private lookupSvc: LookupV2Service,
        public config: UiConfig,
        private matDialog: MatDialog,
        private snackBar: NotificationService,
        private filterSet: FilterSetComponent,
        private configs: DynamicRateCardTabModuleConfig,
        private tabNumber: 1 | 2 | 3 | 4,
        public tabId?: string,
        private afterLoad = () => {
        },
    ) {
    }

    settings: FilterSettings = {
        config: this.config,
        lookupSvc: this.lookupSvc,
        hasDynamicRates: true,
        tabNumber: this.tabNumber,
        screenName: UrlStore.screenNames.rateCard,
    };
    channelGroup = channelGroupFilter(this.settings)
        .OnLoad((items: LookupItem[]) => {
            if (this.channelGroup.Value === null && items.length === 1) {
                this.channelGroup.Default(items[0]);
                this.channelGroup.Toggle(true);
            }
        })
        .OnChange((item: LookupItem) => {
            if (item) {
                if (this.markets) {
                    this.markets.Value = [];
                    const newOptions = this.marketItems.filter(i => i.channelGroups.includes(item.id));
                    this.markets.Options(newOptions);
                    if (this.markets.Value.length === 0 && newOptions.length === 1) {
                        this.markets.Value = newOptions;
                    }
                }
                this.getDynamicFilters(item);
                updatePeriod(item?.id, this.period, this.settings.screenName, this.lookupSvc);
            } else {
                this.dynamicFilters = {};
            }
        });

    markets = marketsFilter(this.settings)
        .OnLoad((items: MarketItem[]) => {
            if (this.marketItems.length === 0) {
                this.marketItems = cloneDeep(items);
            }
            if (this.channelGroup.Value) {
                if (this.markets.Value.length === 0 && items.length === 1) {
                    this.markets.Default(items);
                }
            }
            this.markets.SetHighlightOptions(items);
        })
        .OnChange((items: MarketItem[]) => {
            // when the market changes we filter the list of stations to match
            if (this.stations && this.channelGroup.Value && !this.anyFilterOpen()) {
                const newOptions = this.stationItems.filter(i =>
                    items.map(item => item.id).includes(i.marketId)
                    && i.channelGroups.includes(this.channelGroup.Value.id));
                cascadeToFilter(this.stations, newOptions);
            }
        });


    stations = stationsFilter(this.settings)
        // load the list of stations and filter by selected markets
        .OnLoad((items: StationItem[]) => {
            // store the full list of stations as the base for filters
            if (this.stationItems.length === 0) {
                this.stationItems = cloneDeep(items);
            }
            const newOptions = this.stationItems.filter(i =>
                this.markets.Value.map(market => market.id).includes(i.marketId)
                && i.channelGroups.includes(this.channelGroup.Value.id));
            cascadeToFilter(this.stations, newOptions);
            this.stations.SetHighlightOptions(newOptions);
        })
        .OnChange((items: StationItem[]) => {
            this.stations.SetHighlightOptions(items);
            if (this.channels && this.channelGroup.Value && !this.anyFilterOpen()) {
                const newOptions = this.channelItems.filter(i =>
                    items.map(item => item.id).includes(i.stationId)
                    && i.channelGroup === this.channelGroup.Value.id);
                cascadeToFilter(this.channels, newOptions);
            }
        });

    channels = channelsFilter(this.settings)
        .OnLoad((items: ChannelItem[]) => {
            if (this.channelItems.length === 0) {
                this.channelItems = cloneDeep(items);
            }
            const newOptions = this.channelItems.filter(i =>
                this.stations.Value.map(station => station.id).includes(i.stationId)
                && i.channelGroup === this.channelGroup.Value.id);
            cascadeToFilter(this.channels, newOptions);
        })
        .OnChange((items: ChannelItem[]) => {
            if (items.length === 0) {
                return;
            }
            this.updateSpotTypes();
        });

    period = periodFilter(this.settings)
        .OnLoad(items => {
            const defaultValue = this.period.options.find(option => option.isDefault);
            if (this.period.Value) {
                let periodValue;
                if (this.savedReportLoaded) {
                    // Search using report name in case of updated period ids
                    periodValue = items.find(item => item.name === this.period.Value.name);
                } else {
                    periodValue = items.find(item => item.id === this.period.Value.id);
                }
                this.period.Value = periodValue || defaultValue;
            } else if (defaultValue) {

                this.period.Default(defaultValue);

            }
            if (this.period.Value?.name.toLocaleLowerCase() === 'date') {
                this.dows.Toggle(false);
            } else {
                this.dows.Toggle(true);
                this.dows.options.forEach(item => item.included = true);
            }
        })
        .OnChange(v => {
            // update the views for the date selectors to match the period
            this.startDate.View(v.name as DateView);
            this.endDate.View(v.name as DateView);
            this.resetDates();
            if (v.name.toLocaleLowerCase() === 'date') {
                this.dows.Toggle(false);
            } else {
                this.dows.Toggle(true);
                this.dows.options.forEach(item => item.included = true);
            }
        });

    quarter = new SelectFilter<Quarter>('')
        .Defer(() =>
            this.configs.quarter_dropdown
                ? this.lookupSvc.getRateCardQuarters(true, false)
                : of([]))
        .OnChange((items: LookupItem) => {
            if (this.quarter.Value.startDate != null && this.changeDates &&
                this.configs.quarter_dropdown) {
                this.startDate.Value = moment(this.quarter.Value?.startDate);
                this.endDate.Value = moment(this.quarter.Value?.endDate);
            }
        })
        .EmptySearchText('Load data to see quarter options')
        .InFilterSet(false)
        .Toggle(!this.configs.quarter_dropdown)
        .Filterable(true);

    startDate = startDateFilter()
        .Default(this.defaultStartDate.day(MONDAY))
        .OnChange((date: Moment) => {
            if (this.period.Value) {
                this.resetDates();
                this.endDate.Validate();
            }
        })
        .Clear(() => this.startDate.Value = this.defaultStartDate.day(MONDAY))
        .Filter((date: Moment) => {
            const filterDate = date.clone().subtract(7, 'days');
            return !!this.period.Value?.startDateMap?.[filterDate.year()]?.[filterDate.month() + 1]?.[filterDate.date()];
        })
        .ResetDate((date: Moment) => {
            this.startDate.Value = date;
            this.resetDates();
            return this.startDate.Value;
        })
        .Validator(_ => !this.isTooManyPeriods(), () => `Please limit your search to ${this.period.Value.maxQueryCount} periods or fewer`)
        .ShowQuickSelectFilter(this.configs.quarter_dropdown)
        .QuickSelectFilter(this.quarter.DefaultName(''));

    endDate = endDateFilter()
        .Default(this.defaultEndDate.day(SUNDAY))
        .OnChange(_ => {
            if (this.period.Value) {
                this.resetDates();
                this.startDate.Validate();
            }
        })
        .Clear(() => this.endDate.Value = this.defaultEndDate.day(SUNDAY))
        .Filter((date: Moment) => {
            const filterDate = date.clone().add(7, 'days');
            return date.isAfter(this.startDate.Value)
                && !!this.period.Value?.endDateMap?.[filterDate.year()]?.[filterDate.month() + 1]?.[filterDate.date()];
        })
        .ResetDate((date: Moment) => {
            this.endDate.Value = date;
            this.resetDates();
            return this.endDate.Value;
        })
        .Validator(_ => !this.isTooManyPeriods(), () => `Please limit your search to ${this.period.Value.maxQueryCount} periods or fewer`);

    spotTypes = spotTypesFilter()
        .Defer(() =>
            this.lookupSvc.getSpotTypes(undefined, this.channels.Value.map(i => i.id), this.settings))
        .OnLoad(((items: LookupItem[]) => {
            this.spotTypes.Value = this.spotTypes.options.filter(spotType => this.spotTypes.Value.map(i => i.id).includes(spotType.id));
            this.updateSpotTypes();
        }))
        .OnChange((items: LookupItem[]) => {
            if (items.length === 0) {
                return;
            }
            this.dayparts.isLoading = true;
            const channelIds = this.channels.Value.map(item => item.id);
            const spotTypeIds = this.spotTypes.Value.map(item => item.id);
            this.lookupSvc.getDayparts(channelIds, spotTypeIds, this.settings).pipe(take(1))
                .subscribe((dayparts) => {
                    cascadeToFilter(this.dayparts, dayparts);
                }, err => {
                    console.error(err);
                    this.dayparts.isLoading = false;
                    this.dayparts.loadError = 'Failed to Load';
                });
        });

    dayparts = daypartsFilter(this.settings)
        .Defer(() =>
            this.spotTypes?.Value.length > 0
                ? this.lookupSvc.getDayparts(this.channels.Value.map(item => item.id),
                                             this.spotTypes.Value.map(item => item.id),
                                             this.settings) :
                of([]))
        .OnLoad(((items: DaypartLookupItem[]) => {
            this.dayparts.Value = this.dayparts.options.filter(daypart => this.dayparts.Value.map(i => i.id).includes(daypart.id));
            if (this.dayparts.Value.length === 0) {
                this.dayparts.Value = items;
            }
        }))
        .OnChange((items: LookupItem[]) => {
            if (items.length === 0 || this.channels.Value.length === 0 || this.anyFilterOpen()) {
                return;
            }
            this.getRateCardSegments(items);
            const daypartIdsSelected = items.map(item => item.id);
            const spotTypeIdsSelected = this.spotTypes.Value.map(item => item.id);
            this.lookupSvc.getRateCardSpecialEventTypes(daypartIdsSelected, spotTypeIdsSelected)
                .subscribe((eventTypes) => {
                    cascadeToFilter(this.eventType, eventTypes, []);
                }, err => {
                    console.error(err);
                    this.eventType.isLoading = false;
                    this.eventType.loadError = 'Failed to Load';
                });
        });

    dows = dowsFilter()
        .Defer(() => this.lookupSvc.getDaysOfWeek())
        .OnLoad((items: BooleanLookupItem[]) => {
            items.forEach(item => item.included = item.included ?? true);
        })
        .IsDynamicFilterParent(true);

    eventType = new MultiSelectFilter<LookupItem>('Special Event Type')
        .Defer(() => of([]))
        .OnLoad(_ => {
            if (!(this.eventType.Value.length > 0)) {
                this.eventType.Default([]);
            }
        })
        .Icon('fas fa-gift')
        .Filterable(true)
        .Transform(eventTypes => eventTypes.map(eventType => eventType.id))
        .Toggle(!this.configs.special_events);

    updateSpotTypes(): void {
        this.spotTypes.isLoading = true;
        this.lookupSvc.getSpotTypes(this.channelGroup.Value?.id, this.channels.Value?.map(
            item => item.id,
        ), this.settings)
            .subscribe(result => {
                this.spotTypes.isLoading = false;
                const defaults = result.filter(r => r.isDefault);
                cascadeToFilter(this.spotTypes, result, defaults);
            }, err => {
                console.error(err);
                this.spotTypes.isLoading = false;
                this.spotTypes.loadError = 'Failed to Load';
            });
    }

    resetDates(): void {
        /* `Finds the start date (or end date) that is immediately on or before the current date,
        so when you change periods up we find the period start date that preceeds the date you have selected.
        i.e. if you moved from week to month today we’d bounce from 10/19/2020 → 9/28/2020` */
        if (this.period.Value) {

            const startDateIndex = this.period.Value.startDates.findIndex(date =>
                date > dateFormatter(this.startDate.Value.toDate(), 'yyyy-MM-dd')) - 1;
            if ('Year' === this.period.Value.name) {
                this.startDate.setValueNoOnChange(moment(this.period.Value.startDates[startDateIndex], 'YYYY-MM-DD'));
                this.endDate.setValueNoOnChange(moment(this.period.Value?.endDates[this.period.Value.endDates.findIndex(endDate =>
                    endDate > dateFormatter(this.startDate.Value.toDate(), 'yyyy-MM-dd'))], 'YYYY-MM-DD'));
                return;
            }
            const possibility1 = moment(this.period.Value.startDates[startDateIndex]).diff(moment(this.startDate.Value));
            const possibility2 = moment(this.period.Value.startDates[startDateIndex + 1]).diff(moment(this.startDate.Value));
            const newStartDate = Math.abs(possibility1) > Math.abs(possibility2) ?
                this.period.Value.startDates[startDateIndex + 1] : this.period.Value.startDates[startDateIndex];
            const endDateIndex = this.period.Value.endDates.findIndex(endDate =>
                endDate > dateFormatter(this.endDate.Value.toDate(), 'yyyy-MM-dd')
                && endDate > newStartDate);
            const newEndDate =
                this.period.Value?.endDates[
                    endDateIndex - (moment(newStartDate).isAfter(this.endDate.Value) ? 0 : 1)
                ];
            if (newStartDate && !this.startDate.Value.isSame(newStartDate)) {
                this.startDate.Value = moment(newStartDate, 'YYYY-MM-DD');
            }
            if (newEndDate && !this.endDate.Value.isSame(newEndDate)) {
                this.endDate.Value = moment(newEndDate, 'YYYY-MM-DD');
            }
        }
    }

    isTooManyPeriods(): boolean {
        if (this.period.Value?.maxQueryCount) {
            return this.endDate.Value.diff(this.startDate.Value, 'days')
                > this.period.Value.averageDaysPer * this.period.Value.maxQueryCount;
        }
        return false;
    }

    getDynamicFilters(channelGroupItem) {
        const groupSegmentation = this.config.groupSegmentation[channelGroupItem.id]?.rate_card || [];
        this.dynamicFilters = generateFilters(
            () => this.channels.Value.map(i => i.id),
            () => this.dayparts.Value.map(daypart => daypart.id),
            groupSegmentation,
            (
                channelIds: number[],
                daypartIds: number[],
                segmentation: Segmentation,
                filterValues: {
                    [segmentType: string]: number[];
                },
                clearCache?: boolean,
            ) => this.lookupSvc.getRateCardSegments(channelIds, daypartIds, segmentation, filterValues, clearCache),
        );
    }

    getRateCardSegments(items, clearCache = false) {
        const segmentation = this.config.groupSegmentation[this.channelGroup.Value?.id]?.rate_card;

        if (segmentation?.length) {
            const firstSegmentation = segmentation.sort((first, second) => first.segment_order - second.segment_order)[0];

            const firstFilter = this.dynamicFilters[firstSegmentation.segment_type];
            firstFilter.isLoading = true;
            const channelIdsSelected = this.channels.Value.map(item => item.id);
            const daypartIdsSelected = items.map(item => item.id);
            this.lookupSvc.getRateCardSegments(channelIdsSelected, daypartIdsSelected, firstSegmentation, {}, clearCache)
                .pipe(take(1))
                .subscribe(result => {
                    cascadeToFilter(firstFilter, result);
                    //We clear cache after a new user segment is created.
                }, err => {
                    console.error(err);
                    firstFilter.isLoading = false;
                    firstFilter.loadError = 'Failed to Load';
                });
        }
    }


    getMetricKey(): string {
        const tabMap = {
            1: UrlStore.localStorageKeys.dynamicRateCardMetricsTab1,
            2: UrlStore.localStorageKeys.dynamicRateCardMetricsTab2,
            3: UrlStore.localStorageKeys.dynamicRateCardMetricsTab3,
            4: UrlStore.localStorageKeys.dynamicRateCardMetricsTab4,
        };
        return tabMap?.[this.tabNumber];
    }

    getMetricOrderKey(): string {
        const tabMap = {
            1: UrlStore.localStorageKeys.dynamicRateCardMetricTab1Order,
            2: UrlStore.localStorageKeys.dynamicRateCardMetricTab2Order,
            3: UrlStore.localStorageKeys.dynamicRateCardMetricTab3Order,
            4: UrlStore.localStorageKeys.dynamicRateCardMetricTab4Order,
        };
        return tabMap?.[this.tabNumber];
    }

    getSegmentsKey(): string {
        return UrlStore.localStorageKeys.dynamicRateCardSegments;
    }

    getSegmentsOrderKey(): string {
        return UrlStore.localStorageKeys.dynamicRateCardSegmentsOrder;
    }

    excludedFiltersFromSelectAll = ['markets', 'stations', 'channels'];

    removedFiltersFromSavedReports = ['quarter'];

    metricsData: MetricsData = {
        metricsKey: this.getMetricKey(),
        metricsOrderKey: this.getMetricOrderKey(),
    };

    save = () =>
        saveReport(
            this.matDialog,
            UrlStore.screenNames.rateCard,
            this,
            this.lookupSvc,
            this.snackBar,
            this.metricsData,
            this.getSegmentsKey(),
            this.getSegmentsOrderKey(),
            false,
            true,
            GetTemplateValueForSaveReport(this,
                                          this.excludedFiltersFromSelectAll,
                                          this.removedFiltersFromSavedReports),
            this.filterSet.allFiltersSelectedCheck(),
            this.rateCardTabsData,
        );
    load = () => loadReport(this.matDialog,
                            UrlStore.screenNames.rateCard,
                            this,
                            this.lookupSvc,
                            this.getMetricKey(),
                            this.getMetricOrderKey(),
                            this.getSegmentsKey(),
                            this.getSegmentsOrderKey(),
                            this.afterLoad,
                            this.rateCardTabsData,
                            this.tabId);

    anyFilterOpen: () => boolean = () =>
        Object.keys(this)
            .filter(key => this[key] instanceof DeferrableFilter)
            .filter(key => (this[key] as DeferrableFilter<unknown, unknown>).isOpened)
            .length > 0;
}
