<app-dialog-container [loading]="false" (closeEvent)="closeDialog()" [title]="'Save Report'" data-cy="save-report-dialog-container">
    <div *ngIf="!allFiltersSelected">
        <p class="margin-bottom-1-em bold-font">Please make sure all required fields have been selected.</p>
    </div>
    <div class="is-flex is-flex-direction-column" *ngIf="allFiltersSelected">
        <div class="ra-primary-blue has-text-centered label-wrapper">
            <label class="has-text-weight-semibold">Create New Saved Report</label>
        </div>
        <div>
            <app-filter-input [property]="filters.newOrExistingFilter"></app-filter-input>
        </div>
        <div class="flex-container margin-top-75-em">
        <app-filter-input [property]="filters.nameFilter" appearance="outline" *ngIf="filters.newOrExistingFilter.Value.name === 'Create New'">
        </app-filter-input>
        <app-filter-input [property]="filters.selectExistingReportFilter" *ngIf="filters.newOrExistingFilter.Value.name === 'Update Existing'">

        </app-filter-input>
    </div>

        <app-filter-input [property]="filters.saveAsGlobalFilter"></app-filter-input>
        <app-filter-input [property]="filters.addAsFavoriteFilter"></app-filter-input>
        <div><p class="bold-font" *ngIf="filters.dateRangeOptionsFilter">Date Range Options</p></div>
        <app-filter-input *ngIf="filters.dateRangeOptionsFilter" class="margin-bottom-1-em" [property]="filters.dateRangeOptionsFilter" ></app-filter-input>

        <app-filter-input *ngIf="filters.dateRangeOptionsFilter" [hidden]="filters.dateRangeOptionsFilter.Value.name === 'Save Fixed Dates' || filters.dateRangeOptionsFilter.Value.name === 'Quick Date Options'"
                            [property]="filters.periodSelectFilter"></app-filter-input>
        <app-filter-input *ngIf="filters.dateRangeOptionsFilter" [hidden]="filters.dateRangeOptionsFilter.Value.name === 'Save Fixed Dates' || filters.dateRangeOptionsFilter.Value.name === 'Quick Date Options'"
                            [property]="filters.numberOfPeriodsFilter"></app-filter-input>

        <app-filter-input *ngIf="filters.dateRangeOptionsFilter" [hidden]="filters.dateRangeOptionsFilter.Value.name !== 'Quick Date Options'"
        [property]="filters.quickDateOptionsSelectFilter"></app-filter-input>

        <div class="level-right">
            <a class="button is-primary width-100-pct" (click)="close()" [attr.disabled]="!filters.nameFilter.isValid?true:null">Save</a>
        </div>
    </div>
</app-dialog-container>
