<app-dialog-container class="dialog-wrapper custom-dimension" [loading]="false" (closeEvent)="closeDialog()" [title]="'Save Report'" data-cy="load-report-dialog-container">
    <div class="ra-primary-blue has-text-centered label-wrapper">
        <label class="has-text-weight-semibold">Load Saved Report</label>
    </div>
    <input matInput
           #reportSearch
           placeholder="Search for a report."
           [hidden]="savedReports.length === 0"
           class="search-box"
    >
    <div class="scroll-bar">
        <div *ngIf="savedReports.length > 0">
            <span style="font-weight: bold;">Favorites</span>
        </div>
        <ul data-cy="load-report-dialog-ul" cdkDropList (cdkDropListDropped)="drop($event)">
            <li *ngFor="let reportOption of savedReports | searchFilter: reportSearch.value: 'reportName';" cdkDrag [cdkDragData]="reportOption" style="margin-top: 3px">
                <div *ngIf="reportOption.isFavorited" class="report-container" >
                    <div style="width: 90%;">
                        <div class="report-action-button">
                            <button class="button noshadow report-button" [class.report-active]="report && (report.reportName === reportOption.reportName)" style="width: 100%;" (click)="loadFilters(reportOption)">{{ reportOption.reportName }}</button>
                        </div>
                    </div>
                    <div class="buttons-container">
                        <div class="report-action-button">
                            <button class="button noshadow report-button" (click)="toggleIsFavorited(reportOption)">
                                <i class="fas fa-star"
                                aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="report-action-button">
                            <button class="button noshadow report-button" (click)="openEmailReportDialog(reportOption)">
                            <i *ngIf="isConfiguredEmailReport(reportOption) && !isEndDatePassed(reportOption?.emailReports?.endDate)" class="fas fa-envelope" aria-hidden="true"></i>
                            <i *ngIf="!isConfiguredEmailReport(reportOption) || isEndDatePassed(reportOption?.emailReports?.endDate)" class="far fa-envelope" aria-hidden="true"></i>
                            </button>
                        </div>
                        <!-- EDIT REPORT BUTTON - uncomment when edit report functionality is being enabled -->
                        <!-- <div class="report-action-button">
                                <a class="button" (click)="openEditReportDialog(report)">
                                    <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                                </a>
                            </div> -->
                    <!-- END EDIT REPORT BUTTON-->

                    </div>
                </div>
            </li>
        </ul>
        <div *ngIf="savedReports.length > 0">
            <span style="font-weight: bold;">Reports</span>
        </div>
        <ul cdkDropList (cdkDropListDropped)="drop($event)">
            <li *ngFor="let reportOption of savedReports | searchFilter: reportSearch.value: 'reportName';" cdkDrag [cdkDragData]="reportOption" style="margin-top: 3px">
            <div *ngIf="!reportOption.isFavorited" class="report-container">
                <div style="width: 90%;">
                    <div class="report-action-button report-name">
                        <button class="button noshadow report-button" [class.report-active]="report && (report.reportName === reportOption.reportName)" style="width: 100%;" (click)="loadFilters(reportOption)">{{ reportOption.reportName }}</button>
                    </div>
                </div>
                <div class="buttons-container">
                    <div class="report-action-button">
                        <button class="button noshadow report-button" (click)="toggleIsFavorited(reportOption)">
                            <i class="far fa-star"
                            aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="report-action-button">
                        <button class="button noshadow report-button" (click)="openEmailReportDialog(reportOption)">
                            <i *ngIf="isConfiguredEmailReport(reportOption) && !isEndDatePassed(reportOption?.emailReports?.endDate)" class="fas fa-envelope" aria-hidden="true"></i>
                            <i *ngIf="!isConfiguredEmailReport(reportOption) || isEndDatePassed(reportOption?.emailReports?.endDate)" class="far fa-envelope" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="report-action-button">
                        <button class="button noshadow report-button" (click)="copyReportLink(reportOption)">
                            <i class="fas fa-paperclip"
                            aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            </li>
        </ul>
    </div>
    <div *ngIf="savedReports.length === 0">
        <span style="font-weight: bold;">No reports saved.</span>
    </div>
    <br/>
    <div class="report-container">
        <a class="button is-normal is-success" [attr.disabled]="filter && !isDeleting ? null : true"
            (click)="loadFiltersAndClose()">
            <i *ngIf="!isDeleting" class="fas fa-folder-open" aria-hidden="true"></i>
            <img *ngIf="isDeleting" src="../../../../../assets/images/loader.svg" class="is-size-5"/>
            Load Report
        </a>
        <a class="button is-normal is-danger" [attr.disabled]="filter && !isDeleting ? null : true"
            (click)="openDeleteReportDialog()">
            <i *ngIf="!isDeleting" class="fas fa-trash-alt" aria-hidden="true"></i>
            <img *ngIf="isDeleting" src="../../../../../assets/images/loader.svg" class="is-size-5"/>
            Delete Report
        </a>
    </div>

</app-dialog-container>
