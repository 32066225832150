<app-dialog-container [loading]="false" (closeEvent)="closeDialogFalse()" [title]="'Configure Emailed Report'"
                      data-cy="load-email-report-dialog-container">
    <div class="ra-primary-blue has-text-centered label-wrapper">
        <label class="has-text-weight-semibold">Configure Emailed Report</label>
    </div>

    <app-filter-input [property]="toggleSwitch"></app-filter-input>

    <ng-container *ngIf="toggleSwitch.Value">
        <app-filter-input *ngIf="frequencyFilter" [property]="frequencyFilter"></app-filter-input>
        <app-filter-input *ngIf="isMonthly" [property]="firstDayOfTheMonth"></app-filter-input>
        <app-filter-input *ngIf="(isMonthly && !firstDayOfTheMonth.Value)" [property]="weekOfMonth"></app-filter-input>
        <app-filter-input *ngIf="isWeekly || (isMonthly && !firstDayOfTheMonth.Value)"
                          [property]="dayOfWeekFilter"></app-filter-input>
        <app-filter-input *ngIf="isDaily || isWeekly || isMonthly" [property]="timeFilter"></app-filter-input>
        <app-filter-input *ngIf="frequencyFilter.Value && !isOneTime" [property]="endDateFilter"></app-filter-input>

        <div class="delivery-settings">
            <div class="settings-header">
                <i class="fas fa-envelope"></i>
                <div>
                    <p class="settings-title">Delivery Settings</p>
                    <p *ngIf="userIds.length === 1" class="settings-subtitle">Report will be emailed to your account</p>
                </div>
            </div>
            <div *ngIf="config.roleCapability.rate_optics.general.email_report_to_others"
                 class="is-flex is-flex-direction-column"></div>
            <div *ngIf="userIds.length > 1" class="is-flex align-center">
                <i class="fas fa-user margin-right-half-em"></i>{{userIds.length}} Users Selected
            </div>
            <button class="recipients-button" (click)="openSelectRecipients()">
                <i class="fas fa-users margin-right-half-em"></i>
                Add{{ userIds.length > 1 ? '/Edit' : '' }} Additional Recipients
            </button>
        </div>
    </ng-container>
    <div *ngIf="!toggleSwitch.Value" class="disabled-message">
        This report is disabled
    </div>
    <div class="level-right">
        <a class="button is-primary report-save-button"
           (click)="onSaveFilters()"
           [attr.disabled]="!validateFilters()? true : null"
           [ngClass]="{'disabled-save': !validateFilters()}">Save</a>
    </div>
</app-dialog-container>
